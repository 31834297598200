<template>
  <div class="row">
    <div class="col-12 col-lg-7 c g">
        <div class="card">
            <div class="card-header">
                <h5>
                    <i class="fa fa-check-square"></i>
                    اختر الطلاب المراد نقلهم
                </h5>
            </div>
            <div class="card-footer">
                <app-collapse>
                    <app-collapse-item v-for="classname in classes" :key="classname.title" class="itemm" :title="'👥 ' + classname.title">
                        <app-collapse-item v-for="classroom in classname.classrooms" :key="classroom" class="itemm" :title="'👨‍🏫 الفصل ' + classroom">
                            <a href="javascript:;" style="position: relative; top: -10px" @click="pushClassroom(classname.title, classroom)"><u><i class="fa fa-check-square"></i> تحديد الكل</u></a>
                            <template v-for="student in students">
                                <template v-if="student.classname == classname.title && student.classroom == classroom">
                                    <b-form-checkbox :key="student._id"
                                        v-model="selected"
                                        :value="student._id"
                                    >
                                        {{ student.name }}
                                    </b-form-checkbox>
                                </template>
                            </template>
                        </app-collapse-item>
                    </app-collapse-item>
                </app-collapse>
            </div>
            <div class="card-footer">
                <a href="javascript:;" class="text-danger" @click="toggl()">هل تريد تغيير اسم الصف او حذفه؟ <u>اضغط هنا</u></a>
                <div class="table-responsive dnone asdxr4222 g">
                    <table class="table table-hover table-bordered table-sm">
                        <thead>
                            <th>
                                الفصل
                            </th>
                            <th>
                                تغيير الصف
                            </th>
                            <th>
                                تغيير الفصل
                            </th>
                            <th>
                                حذف
                            </th>
                        </thead>
                        <tbody>
                            <template v-for="classname in classes">
                                <template v-for="classroom in classname.classrooms">
                                    <tr :key="classroom + classname.title" class="ccc">
                                        <td>
                                            {{ classname.title }} - {{ classroom }}
                                        </td>
                                        <td>
                                            <input type="text" :id="'classname_' + classname.title" :value="classname.title" style="border: none; width: 100%; height: 24px" placeholder="اكتب اسم الصف الجديد هنا...">
                                        </td>
                                        <td>
                                            <input type="text" :id="'classroom_' + classname.title + ',' + classroom" :value="classroom" style="border: none; width: 100%; height: 24px" placeholder="اكتب اسم الفصل الجديد هنا...">
                                        </td>
                                        <td>
                                            <button class="btn btn-sm btn-danger" @click="deleteClassroom(classname.title, classroom)">
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                    </table>
                <div class="col-12 text-center g"><br>
                    <button class="btn btn-primary" @click="rename()">
                        <i class="fa fa-check"></i>
                        تعديل اسماء الفصول الآن
                    </button>
                </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-5 g c">
        <div class="card">
            <div class="card-header">
                <h5>
                    <i class="fa fa-arrow-left"></i>
                     نقل الطلاب المُحددين الى:
                </h5>
            </div>
            <div class="card-footer">
                <div class="alert alert-info text-center g">
                    <span class="text-primary">تم تحديد {{ selected.length }} لنقلهم.</span>
                </div>
                <div class="col-12 g" v-if="!manual">
                    <span>اختيار صف معين ( <a href="javascript:;" @click="manual = true"><i class="fa fa-plus"></i> اضافة صف/ فصل جديد</a> )</span>
                    <select class="form-control" v-model="mclassname" @change="cc2();">
                        <option value="">-- اختر --</option>
                        <option v-for="c in classes" :key="c._id" :value="c.title">
                            {{ c.title }}
                        </option>
                    </select>
                </div>
                <div class="col-12 g" v-if="!manual">
                    <span>اختيار فصل معين</span>
                    <select class="form-control" v-model="mclassroom">
                        <option value="" v-if="mclassname == ''">-- اختر الصف اولاً --</option>
                        <option value="" v-if="mclassname != ''">-- اختر الفصل --</option>
                        <option v-for="c in classrooms2" :key="c" :value="c">
                            {{ c }}
                        </option>
                    </select>
                </div>
                <div class="form-group g" v-if="manual">
                  <span for="">الصف</span>
                  <input type="text"
                    class="form-control" v-model="mclassname" placeholder="اكتب هنا...">
                </div>
                <div class="form-group g" v-if="manual">
                  <span for="">الفصل</span>
                  <input type="text"
                    class="form-control" v-model="mclassroom" placeholder="اكتب هنا...">
                </div>
                <div class="col-12 text-center g">
                    <button class="btn btn-primary" @click="movenow()">
                        <i class="fa fa-check"></i>
                        نقل الطلاب الآن
                    </button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { BModal, VBModal, BFormCheckbox, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
export default {
    components: {
        BFormCheckbox,
        BModal,
        AppCollapse,
        AppCollapseItem,
        BDropdown,
        BDropdownItem,
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            students: [],
            classes: [],
            classrooms: [],
            classrooms2: [],
            mclassname: "",
            mclassroom: "",
            changeclassname: {},
            selected: [],
            manual: false
        }
    },
    created(){
        if(!checkPer("students|students|move")){
            this.$router.push('/NotPermitted')
            return false;
        }
        this.fec()
    },
    methods: {
        toggl(){
            $(".asdxr4222").toggle('slow')
        },
        fec(){
            var g = this;
            $.post(api + '/user/students/classes', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.classes = r.response
                    $.post(api + '/user/students/list', {
                        jwt: g.user.jwt,
                        page: 1,
                        perpage: 10000
                    }).then(function(r){
                        g.loading = false
                        if(r.status != 100){
                            alert(r.response)
                        }else{
                            g.students = r.response
                        }
                    }).fail(function(){
                    
                    })
                }
            }).fail(function(){
            })
        },
        cc(){
            var g = this
            g.classes.forEach(function(a){
                if(a.title == g.classname){
                    g.classrooms = a.classrooms
                }
            })
        },
        cc2(){
            var g = this
            g.classes.forEach(function(a){
                if(a.title == g.mclassname){
                    g.classrooms2 = a.classrooms
                }
            })
        },
        pushClassroom(a,b){
            var g = this;
            g.students.forEach(function(c){
                if(c.classname == a && c.classroom == b){
                    if(g.selected.includes(c._id)){
                        g.selected = g.selected.filter(function(item) {
                            return item !== c._id
                        })
                    }else{
                        g.selected.push(c._id)
                    }
                }
            })
        },
        deleteClassroom(a,b){
            var g = this;
            if(confirm("متأكد من حذف الفصل وطلابه؟")){
                $.post(api + '/user/students/delete-classroom', {
                    jwt: this.user.jwt,
                    classname: a,
                    classroom: b,
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response, 200)
                    }else{
                        alert("تم الحذف بنجاح", 100)
                        g.selected = [];
                        g.fec()
                    }
                }).fail(function(){
                    alert("خطأ", 200)
                })
            }
        },
        movenow(){
            var g = this;
            if(!g.selected.length){
                alert("قم باختيار الطلاب المراد نقلهم", 200)
                return false;
            }
            if(g.mclassname == ""){
                alert("قم باختيار الصف المراد النقل اليه", 200)
                return false;
            }
            if(g.mclassroom == ""){
                alert("قم باختيار الفصل المراد النقل اليه", 200)
                return false;
            }
            $.post(api + '/user/students/move', {
                jwt: this.user.jwt,
                selected: JSON.stringify(g.selected),
                to_classname: g.mclassname,
                to_classroom: g.mclassroom,
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    alert("تم النقل بنجاح", 100)
                    g.selected = [];
                    g.fec()
                }
            }).fail(function(){
                alert("خطأ", 200)
            })
        },
        rename(){
            var arr = [];
            var g = this;
            $(".ccc").each(function(){
                arr.push({
                    old_classname: $(this).find("td").first().text().split("-")[0].trim(),
                    old_classroom: $(this).find("td").first().text().split("-")[1].trim(),
                    classname: $(this).find("td:nth-child(2)").find("input").val().trim(),
                    classroom: $(this).find("td:nth-child(3)").find("input").val().trim(),
                })
            })
            $.post(api + '/user/students/rename', {
                jwt: this.user.jwt,
                arr: JSON.stringify(arr)
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    alert("تم التغيير بنجاح", 100)
                    g.selected = [];
                    g.fec()
                }
            }).fail(function(){
                alert("خطأ", 200)
            })
        }
    }
}
</script>

<style>

</style>